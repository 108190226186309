<template>
<div id="indexlayout">
  <layout-index-left />
  <div id="indexlayout-right" :class="{ 'fiexd-header': siteFiexdHeader }">
    <layout-index-right-top />
    <div class="indexlayout-right-main">
      <div class="main-conent main-conent-minheight">
        <!--查询框-->
        <el-card shadow="never" class="border-none" style="margin-top: 20px">
          <el-row>
            <el-col :span="5">
              <span class="title">姓名</span>
              <el-input class="margin-l10" style="width: 270px" placeholder="请输入姓名" clearable v-model="queryform.name">
              </el-input>
            </el-col>
            <el-col :span="5">
              <span class="title">手机</span>
              <el-input class="margin-l10" style="width: 270px" placeholder="请输入手机" clearable v-model="queryform.phone">
              </el-input>
            </el-col>
            <el-col :span="6">
              <span class="title" style="width: 120px">证件号码</span>
              <el-input class="margin-l10" style="width: 270px" placeholder="请输入证件号码" clearable v-model="queryform.identity">
              </el-input>
            </el-col>
          </el-row>
          <el-row style="margin-top: 20px">
    
            <el-button type="primary" @click="readCard">读取身份证查询</el-button>
            <el-button type="primary" @click="queryForm">查 询</el-button>

          </el-row>
        </el-card>

        <!--查询结果-->
        <el-card  v-if="step==1" shadow="never" class="border-none" style="margin-top: 20px">
          <el-table :data="tableData" ref="multipleTable" stripe >

            <el-table-column fixed type="index" label="序号">
            </el-table-column>
            <el-table-column fixed prop="name" label="姓名">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top">
                  <p>姓名: {{ scope.row.name }}</p>
                  <p>电话: {{ scope.row.phone }}</p>
                  <p>住址: {{ scope.row.address }}</p>
                  <div slot="reference" class="name-wrapper">
                    <el-tag size="medium">{{scope.row.name}}</el-tag>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column prop="age" label="年龄" width="60">
            </el-table-column>
            <el-table-column prop="sex" label="性别" width="60">
            </el-table-column>
            <el-table-column prop="phone" label="手机">
            </el-table-column>
            <el-table-column prop="identity" label="证件号码" min-width="100">
            </el-table-column>
            <el-table-column prop="address" label="详细地址" min-width="110"  :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="subtown" label="归属社区">
            </el-table-column>、
            <el-table-column prop="station" label="建档机构"  :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="doctor" label="建档医生">
            </el-table-column>
            <el-table-column prop="build_date" label="建档时间" min-width="90">
            </el-table-column>
 
            <el-table-column fixed="right" label="操作" width="100">
              <template slot-scope="scope">
                <el-button @click="showOrder(scope.row)" type="primary" size="small">开单</el-button>
            
              </template>
            </el-table-column>
          </el-table>
        </el-card>

        <!--开单详情 v-if="step==2"-->
        <el-row v-if="step==2" >
          <el-card v-if="flag==true" shadow="never" class="border-none" style="margin-top: 20px">
            <span class="name" >{{order.name}}</span> 已于 <span class="datetime" >{{ order.create }}</span> 参加 {{ order.app }} ！
          </el-card>
        </el-row>

        <el-row v-if="step==2" >
          <!--体检类型-->
          <el-col :span="6" style="">
            <el-card shadow="never" class="border-none" style="
                  margin-top: 10px;
                  height: 440px;
                  border-radius: 0;
                  border-right: 1px solid #f1f1f1;

                ">
              体检项目
              <el-row style="margin-top: 20px">
                <el-col :span="24">
                  <span class="title">项目类型</span>
                  <el-cascader v-model="form.app" :options="options" @change="handleChange" clearable style="width: 73.2%;font-size:18px;"></el-cascader>
                </el-col>
              </el-row>
              <el-row>
                <el-checkbox-group v-model="form.tables">
                  <el-checkbox style="margin-top: 20px; width: 100%" v-for="item in tables_arr" :label="item" :key="item" :disabled="disabled">{{ item.label }}</el-checkbox>
                </el-checkbox-group>
              </el-row>
            </el-card>
          </el-col>
          <!--基础信息-->
          <el-col :span="6">
            <el-card shadow="never" class="border-none" style="
                  margin-top: 10px;
                  height: 440px;
                  border-radius: 0;
                  border-right: 1px solid #f1f1f1;
                ">
              基础信息
              <el-row class="line">
                <div class="title">姓名</div>
                <el-input formstyle="width:80%" placeholder="姓名" clearable v-model="form.name">
                </el-input>
              </el-row>
              <el-row class="line">
                <div class="title">性别</div>
                <el-input formstyle="width: 80%" placeholder="性别" clearable v-model="form.sex" disabled>
                </el-input>
              </el-row>
              <el-row class="line">
                <div class="title">年龄</div>
                <el-input formstyle="width: 80%" placeholder="年龄" clearable v-model="form.age" disabled>
                </el-input>
              </el-row>
              <el-row class="line">
                <div class="title">身份证</div>
                <el-input formstyle="width: 80%" placeholder="身份证" clearable v-model="form.identity">
                </el-input>
              </el-row>
              <el-row class="line">
                <div class="title">地址</div>
                <el-input formstyle="width: 80%" placeholder="地址" clearable v-model="form.address">
                </el-input>
              </el-row>
              <el-row class="line">
                <div class="title">手机</div>
                <el-input formstyle="width: 80%" placeholder="手机" clearable v-model="form.phone">
                </el-input>
              </el-row>
              <el-row class="line">
                <div class="title">备注</div>
                <el-input formstyle="width: 80%" placeholder="备注" clearable v-model="form.note">
                </el-input>
              </el-row>
            </el-card>
          </el-col>

          <!--体征及诊断-->
          <el-col :span="6">
            <el-card shadow="never" class="border-none" style="
                  margin-top: 10px;
                  height: 440px;
                  border-radius: 0;
                  border-right: 1px solid #f1f1f1;
                ">
              体征
              <el-row class="line">
                <div class="title">收缩压</div>
                <el-input v-model="form.s_press"  @keydown.enter="nextFocus"  style="margin-right:10px" type="number" placeholder="收缩压" clearable>
                </el-input>
             <div class="title">mmHg</div>
              </el-row>
              <el-row class="line">
                <div class="title">舒张压</div>
                <el-input v-model="form.d_press" @keydown.enter="nextFocus" style="margin-right:10px" type="number" placeholder="舒张压" clearable>
                </el-input>
                 <div class="title">mmHg</div>
              </el-row>
              <el-row class="line">
                <div class="title">身高</div>
                <el-input v-model="form.height" @keydown.enter="nextFocus" style="margin-right:10px" type="number" placeholder="身高" clearable>
                </el-input>
                   <div class="title">CM</div>
              </el-row>
              <el-row class="line">
                <div class="title">体重</div>
                <el-input v-model="form.weight" @keydown.enter="nextFocus" style="margin-right:10px" formstyle="width: 60%" placeholder="体重" type="number" clearable >
                </el-input>
                 <div class="title">KG</div>
              </el-row>
            </el-card>
          </el-col>

          <!--基础信息-->
          <el-col :span="6">
            <el-card shadow="never" class="border-none" style="
                  margin-top: 10px;
                  height: 440px;
                  border-radius: 0;
                  border-right: 1px solid #f1f1f1;
                ">
              初步诊断
              <el-row>
                <el-checkbox-group v-model="form.disease" @change="showChanage">
                  <el-checkbox style="margin-top: 20px; width: 100%" v-for="item in disease_arr" :label="item" :key="item"></el-checkbox>
                </el-checkbox-group>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
        <el-row v-if="step==2" >
          <el-card shadow="never" class="border-none menu">
            <el-button type="primary" @click="submitForm">打印体检单</el-button>
             <el-button type="primary" @click="hiddenForm">取消</el-button>
          </el-card>
        </el-row>
    
      </div>
      <layout-index-right-footer />
    </div>
  </div>

</div>
</template>

<script>
import {
  mapGetters
} from "vuex";
import LayoutIndexLeft from "@/layout/Index/LayoutIndexLeft";
import LayoutIndexRightTop from "@/layout/Index/LayoutIndexRightTop";
import LayoutIndexRightFooter from "@/layout/Index/LayoutIndexRightFooter";

import request from "@/service/lib/request";
import { analyzeIDCard } from "@/utlis/tool";
export default {
  components: {
    LayoutIndexLeft,
    LayoutIndexRightTop,
    LayoutIndexRightFooter
  },
  data() {
    return {
      order:{
        name:'张胜',
        create_date:'2012、12、12',
        app:'重点人群免费体检'
      },
      flag:false,
      disease: [],
      tables_arr: [],
      app: {},
      disabled: false,
      tableData: [],
      step: 1,
      queryform: {
        name: '',
        phone: '',
        identity: ''
      },
      form: {
        tables: [],
        app: [3],
        disease: [],
        name: "",
        sex: "",
        age: "",
        identity: "",
        address: "",
        phone: "",
        note: "",
        d_press: '',
        s_press: '',
        height: '',
        weight: ''
      },
      options: [{
          label: "老年人免费体检",
          value: 1
        },
        {
          label: "重点人群免费体检",
          value: 2
        },
        {
          label: "自定义体检项目",
          value: 3
        }
      ],

      //disease_arr: ["高血压", "糖尿病", "严重精神障碍", "脑卒中",'老年人']
      disease_arr: ["高血压", "糖尿病",'老年人','其他重点人群']
    };
  },
  computed: {
    ...mapGetters(["siteFiexdHeader"])
  },
  created() {
    // this.initOption();
  },
  mounted() {
    this.init();
  },
  methods: {
    nextFocus(event){
      const DOM = event.target;
          const nextDOM = DOM.nextElementSibling;
          nextDOM.focus();

    },
    hiddenForm(){
      this.step=1;
      this.flag=false;
    },
    showOrder(row){
            this.step=2;

            let man =row;
            this.form.name = man.name;
            this.form.identity = man.identity;
            this.form.phone = man.phone;
            this.form.address = man.address;
            this.form.note = man.note;
            this.form.sex=man.sex;
            this.form.age=man.age;
            // console.log('this.form.disease',this.form.disease);

            //console.log('man.disease_arr',man.disease_arr);
            let _m = man.disease_arr;
            let t = [];
            for (let i = 0; i < _m.length && i < this.disease.length; i++) {
              if (_m[i] === 1) {
                t.push(this.disease[i]);
              }
            }
            this.form.disease = t;
            this.queryOrder(man.identity);
    },
    showChanage() {
      console.log(this.form.disease);
    },
    queryOrder(identity){

      request({
        url: "/order/pc_query?identity="+identity,
        method:'get'

      }).then(res=>{
        //console.log("身份证查询！",res);
        if(res.code==='success'){
          this.order=res.data;
          this.flag=true;
        }else{
          this.flag=false;
        }
      
      }).catch(err=>{
        console.log(err);
        //this.$message.error(err.message);
      });
    },
    init() {
      request({
          url: "/tables/",
          method: "get"
        })
        .then((res) => {
          this.tables_arr = res;
        })
        .catch((err) => {
          console.log(err.message);
        });
      request({
          url: "/app/",
          method: "get"
        })
        .then((res) => {
          this.app = res;
        })
        .catch((err) => {
          console.log(err.message);
        });
      request({
          url: "/diease/",
          method: "get"
        })
        .then((res) => {
          this.disease = res;
        })
        .catch((err) => {
          console.log(err.message);
        });
    },

    handleChange(e) {
      //体检项目选择
      if (e[0] === 1 || e[0] === 2) {
        let t = [];
        let data = this.app[e[0]];
        for (let i = 0; i < data.length; i++) {
          t.push(this.tables_arr[data[i] - 1]);
        }
        this.form.tables = t;
        this.disabled = true;
      } else {
        this.disabled = false;
        this.form.tables = [];
      }
      //console.log("this.form:====", this.form);
    },
    submitForm() {
     // console.log("提交表单", this.disease_arr, this.form.disease);
      //console.log(' this.form,', this.form);
      if(this.form.tables.length===0){
        this.$message.info('请先选择体检表格！');
        return false; 
      }
      if(this.form.s_press!==''){
        if(parseInt( this.form.s_press)<80 ||parseInt( this.form.s_press)>220 ){
        this.$message.info('收缩压错误！收缩压范围：80-220 mmHg');
        return false; 
      }
      }
       if(this.form.d_press!==''){
           if(parseInt( this.form.d_press)<50 ||parseInt( this.form.d_press)>120 ){
        this.$message.info('舒张压错误！舒张压范围：50-120 mmHg');
        return false; 
      }
       }
      
      if(this.form.height!==''){
 if(parseInt( this.form.height)<120 || parseInt( this.form.height)>220){
        this.$message.info('身高错误！身高范围：120-220！');
        return false; 
      }
      }
      
      if(this.form.weight!==''){
       if(parseInt( this.form.weight)>180 ||parseInt( this.form.weight)>180 ){
        this.$message.info('体重错误！身高范围：40-100！');
        return false; 
      }
      }
      let form = this.form;
      let  _disease=[0,0,0,0];
     // alert(JSON.stringify(this.disease_arr));
      for (let i = 0; i < this.form.disease.length; i++) {
     
      let index = this.disease_arr.indexOf(this.form.disease[i]);
       index >= 0 ? _disease[index]=1:_disease[index]=0;
      }
      
      let _tables = [];

      //console.log('this.form.tables', this.form.tables);
      for (let i = 0; i < this.form.tables.length; i++) {
        _tables.push(this.form.tables[i].value);

      }
      //alert('form.diease' +JSON.stringify(this.form.disease));
      let data = {
        app: form.app[0],
        tables: _tables,
        disease: _disease,
        name: form.name,
        identity: form.identity,
        address: form.address,
        phone: form.phone,
        note: form.note,
        d_press: form.d_press==='' ?0 : parseInt(form.d_press),
        s_press:  form.s_press==='' ?0 : parseInt(form.s_press),
        height:  form.height==='' ?0 : parseInt(form.height),
        weight:  form.weight==='' ?0 : parseInt(form.weight)
      };
      console.log(data);
      request({
        url: "/order/create/",
        method: "post",
        data:data
      }).then(res => {
        console.log(res);
        if(res.code==='success'){
          window.open(res.data,'_blank');
          this.queryform={
            name:'',
            identity:'',
            phone:''
          };
          this.form.d_press='';
          this.form.s_press='';
          this.form.height='';
          this.form.weight='';
          this.hiddenForm();
        
        }else{
          console.log('fail');
          //this.$message.error(res.message);
        }
      }).catch(err=>{
        console.log(err.message);
        //this.$message.error(err.message);
      });
    },
        readCard() {
          request({
            url:'http://127.0.0.1:19196/readcard'
          }).then(res=>{

          //alert(JSON.stringify(res));
          this.queryform.name='';
          this.queryform.phone='';
          this.queryform.identity=res.certNumber;
          this.queryForm();
          });
           
    },
    queryForm() {
      // console.log(this.form);
     this.setp=1;
      request({
          url: "/man/",
          method: "get",
          params: {
            name: this.queryform.name,
            identity: this.queryform.identity,
            phone: this.queryform.phone

          }
        })
        .then((res) => {
         // alert(JSON.stringify(res));
          console.log("查询结果", res, this.form.disease, this.form.tables);
          //this.tableData = res.data;
                      let t = [];
            for (let i = 0; i < res.data.length; i++) {
              let item = res.data[i];
              item["sex"] = analyzeIDCard(item["identity"]).sex;
              item["age"] = analyzeIDCard(item["identity"]).age;
              t.push(item);
            }
            this.tableData = t;
            console.log("thistabdata",t);
          this.setp=1;
   
         
        })
        .catch((err) => {
          console.log(err.message);
           
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/variables.scss";

.menu {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.line {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  line-height: 35px;
}

.title {
  width: 80px;
  margin-right: 10px;

  font-size: 14px;

}

.red_day {
  color: red;
  font-weight: 700;
}

.scrolldiv {
  height: 4em;
  width: 100%;
  overflow: auto;
}

#indexlayout {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

#indexlayout-right {
  position: relative;
  flex: 1;
  overflow: auto;
  background-color: $mainBgColor;

  &.fiexd-header {
    display: flex;
    flex-direction: column;

    .indexlayout-right-main {
      flex: 1;
      overflow: auto;
    }
  }
}

.aradio {
  width: 500px;
  line-height: 3.2;
}
.name{
  color:#f1690d;
  font-size:24px;
  font-weight: 600;
}
</style>
