export function getyyyyMMdd(date) {
  var d = new Date(date);
  var curr_date = d.getDate();
  var curr_month = d.getMonth() + 1;
  var curr_year = d.getFullYear();
  String(curr_month).length < 2
    ? (curr_month = "0" + curr_month)
    : curr_month;
  String(curr_date).length < 2 ? (curr_date = "0" + curr_date) : curr_date;
  var yyyyMMdd = curr_year + "-" + curr_month + "-" + curr_date;
  return yyyyMMdd;
}
export function getNextDay(date) {
  return new Date(new Date(date).getTime() + 24 * 3600 * 1000);
}


export function isPhone(rule, value, callback) {
  if (value === "") {
    callback(new Error("请输入手机号"));
  } else {
    if (!/^1[3456789]\d{9}$/.test(value)) {
      callback(new Error("请输入正确的手机号"));
    } else {
      callback();
    }
  }
}


//校验码校验
function checkCode(val) {
  var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
  var code = val.substring(17);
  if (p.test(val)) {
    var sum = 0;
    for (var i = 0; i < 17; i++) {
      sum += val[i] * factor[i];
    }
    if (parity[sum % 11] === code.toUpperCase()) {
      return true;
    }
  }
  return false;
}


//省份校验
const checkProv = val => {
  var pattern = /^[1-9][0-9]/;
  var provs = { 11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林", 23: "黑龙江 ", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西", 37: "山东", 41: "河南", 42: "湖北 ", 43: "湖南", 44: "广东", 45: "广西", 46: "海南", 50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏 ", 61: "陕西", 62: "甘肃", 63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门" };
  if (pattern.test(val)) {
    if (provs[val]) {
      return true;
    }
  }
  return false;
};

//出生日期码校验
const checkDate = val => {
  var pattern = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/;
  if (pattern.test(val)) {
    var year = val.substring(0, 4);
    var month = val.substring(4, 6);
    var date = val.substring(6, 8);
    var date2 = new Date(year + "-" + month + "-" + date);
    if (date2 && date2.getMonth() === (parseInt(month) - 1)) {
      return true;
    }
  }
  return false;
};


//获取年龄和性别
const getAgeAndSex = idCard => {
  //let info = {};
  let year = new Date().getFullYear();
  let age = (idCard.length === 18) ? year - parseInt(idCard.slice(6, 10)) : year - parseInt("19" + idCard.slice(6, 8));
  let order = (idCard.length === 18) ? idCard.slice(-2, -1) : idCard.slice(-1);
  return {
    "age": age,
    "sex": order % 2 === 0 ? '女' : '男'
  };
};


//检验身份证
export const checkIdcard = val => {
  let result = { "is_valid": false, "age": 0, "sex": "" };
  console.log("checkCode(val)", this.checkCode(val), val);
  if (checkCode(val)) {
    var date = val.substring(6, 14);
    if (checkDate(date)) {
      if (checkProv(val.substring(0, 2))) {
        let info = getAgeAndSex(val);
        result = {
          "is_valid": true,
          "age": info.age,
          "sex": info.sex
        };
      }
    }
  }
  return result;
};


export function analyzeIDCard(IDCard) {
  var sexAndAge = {};
  //获取用户身份证号码
  var userCard = IDCard;
  //如果身份证号码为undefind则返回空
  if (!userCard || userCard.length < 17) {
    return sexAndAge;
  }
  //获取性别
  if (parseInt(userCard.substr(16, 1)) % 2 === 1) {
    sexAndAge.sex = '男';
  } else {
    sexAndAge.sex = '女';
  }
  //获取出生年月日
  //userCard.substring(6,10) + "-" + userCard.substring(10,12) + "-" + userCard.substring(12,14);
  var yearBirth = userCard.substring(6, 10);
  var monthBirth = userCard.substring(10, 12);
  var dayBirth = userCard.substring(12, 14);
  //获取当前年月日并计算年龄
  var myDate = new Date();
  var monthNow = myDate.getMonth() + 1;
  var dayNow = myDate.getDay();
  var age = myDate.getFullYear() - yearBirth;
  if (monthNow < monthBirth || (monthNow === monthBirth && dayNow < dayBirth)) {
    age--;
  }
  //得到年龄
  sexAndAge.age = age;
  //返回性别和年龄
  return sexAndAge;
}

export function isCardID(rule, value, callback) {
  //console.log(value);
  if (!/(^\d{15}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
    callback(new Error("你输入的身份证长度或格式错误"));
  }
  //身份证城市
  var aCity = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    91: "国外"
  };
  if (!aCity[parseInt(value.substr(0, 2))]) {
    callback(new Error("你的身份证地区非法"));
  }

  // 出生日期验证
  var sBirthday = (
    value.substr(6, 4) +
    "-" +
    Number(value.substr(10, 2)) +
    "-" +
    Number(value.substr(12, 2))
  ).replace(/-/g, "/"),
    d = new Date(sBirthday);
  if (
    sBirthday !==
    d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate()
  ) {
    callback(new Error("身份证上的出生日期非法"));
  }

  // 身份证号码校验
  var sum = 0,
    weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
    codes = "10X98765432";
  for (var i = 0; i < value.length - 1; i++) {
    sum += value[i] * weights[i];
  }
  var last = codes[sum % 11]; //计算出来的最后一位身份证号码
  if (value[value.length - 1] !== last) {
    callback(new Error("身份证号非法"));
  }

  callback();
}

export function ageRange(rule, value, callback) {
  let year = value.slice(6, 10);

  let now = new Date();
  let diff = now.getFullYear() - year;
  //131082196303070754
  //console.log("ageRange", diff, "year", year);
  if (diff >= 18) {
    callback();
  } else {
    callback(new Error("该人员年龄:" + diff + ",暂时仅限至少18岁"));
  }

}

/*测试*/
//var ID = analyzeIDCard('510612199004174732');

